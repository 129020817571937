import React from 'react';
import SEO from '../../components/seo';
import Layout from '../../components/layout';

const DownloadsPage = () => {
	return (
		<Layout>
			<SEO title={"Downloads"}/>
			<h1 className={"title"}>Downloads</h1>
			<ul>
				<li>
					<a href="https://drive.google.com/file/d/1TWe8q87OtqoAys0pa0jSmRxR9ybscdWM/view?usp=sharing">
						KMITL Sustainability Report 2018 - 2019
					</a>
				</li>
			</ul>
		</Layout>
	)
};

export default DownloadsPage;
